//store/modules/auth.js

import axios from 'axios';
import MainApp from '@/router'

const state = {
    user: null,
    token: null,
};

const getters = {
    isAuthenticated: function(){
        const account = getters.getUser

        if(account!=null || account!=undefined){
            return true
        }else{
            return false
        }
    },  
      
    StateUser: state => state.user,

    getUser: state => state.user,

    userToken: state => state.token
};

const actions = {
    checkAuth({dispatch},url){
        const user = getters.getUser
        if(!user ){
            if(url.path!='/login'){
                MainApp.push("/login")
            }
        }
    },
    async Register({dispatch}, form) {
        const response = await axios.post('register', form)
        return response
    },

    async Login({commit}, form) {
        const response = await axios.post('login', form)
        
        await commit('setUser', response.data.user)
        return response
    },

    async updateProfile({dispatch}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: getters.userToken ? `Bearer ${ getters.userToken }` : '',
            }
        }

        const response = await axios.post('profile-update',form, headers)
        return response
    },

    async getProfile({dispatch}, form) {
        const headers =  {
            Authorization: getters.userToken ? `Bearer ${ getters.userToken }` : '',
        }


        const response = await axios.get('user-detail',{ params: {id: form.id}, headers: headers})
        return response
    },

    setLogin({commit}, data){
    
        $cookies.set("IGW_TOKEN",data.token)
        $cookies.set("IGW_USER",data.user)
        commit('setuserdata',data)
    },

    async Logout({commit}){
        let user = null
        $cookies.remove("IGW_TOKEN")
        $cookies.remove("IGW_USER")
        commit('logout')
        return true;
    },

    setUserPhone({commit}, phone){
        state.phone = phone
    }
};

const mutations = {
    setUser(state, username){
        state.user = username
    },
    setPosts(state, posts){
        state.posts = posts
    },
    logout(state){
        state.user      = null
        state.token     = null
    },
    setuserdata(state,data){
        state.user      = data.user
        state.token     = data.token
    }
};

export default {
  state,
  getters,
  actions,
  mutations
};
