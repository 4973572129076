//store/modules/auth.js

import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async searchPost({dispatch, rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters["userToken"] ? `Bearer ${rootGetters["userToken"]}` : '',
        }

        const response = await axios.get('post-search', { params: form, headers: headers})
        return response
    },

    async getPublishedPosts({dispatch, rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters["userToken"] ? `Bearer ${ rootGetters["userToken"] }` : '',
            }

        console.log(rootGetters["userToken"],"TOKEN")

        const response = await axios.get('post-list-published',{ params: form, headers: headers})
        return response
    },
    
    async getPosts({dispatch, rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters["userToken"] ? `Bearer ${ rootGetters["userToken"] }` : '',
            }

        console.log(rootGetters["userToken"],"TOKEN")

        const response = await axios.get('post-list',{ params: form, headers: headers})
        return response
    },

    async createUpdatePost({dispatch, rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters["userToken"] ? `Bearer ${rootGetters["userToken"]}` : '',
            }
        }

        const response = await axios.post('post-create',form, headers)
        return response
    },
    
    async updatePost({dispatch, rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters["userToken"] ? `Bearer ${rootGetters["userToken"]}` : '',
            }
        }

        const response = await axios.post('post-update',form, headers)
        return response
    },
    
    async detailPostByPermalink({dispatch,rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters["userToken"] ? `Bearer ${rootGetters["userToken"]}` : '',
            }
        
        const response = await axios.get('post-detail-by-permalink',{ params: form, headers: headers})
        return response
    },
    
    async detailPost({dispatch,rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters["userToken"] ? `Bearer ${rootGetters["userToken"]}` : '',
            }
        
        const response = await axios.get('post-detail',{ params: form, headers: headers})
        return response
    },
    
    async deletePost({dispatch,rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters["userToken"] ? `Bearer ${rootGetters["userToken"]}` : '',
            }
        
        const response = await axios.delete('post-delete',{ params: form, headers: headers})
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
