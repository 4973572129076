import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async contactSubmit({dispatch}, form) {
        const response = await axios.post('contact-submit', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        return response
    }

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
