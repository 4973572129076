import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../templates/mwg-cp/homepageView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../templates/mwg-cp/loginView.vue')
  },

  //==========================
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/MainView.vue'),
    children: [
      {
        path: '/posts',
        name: 'post',
        component: () => import('../views/post/indexView.vue'),
      },
      {
        path: '/posts/create',
        name: 'post-create',
        component: () => import('../views/post/createView.vue'),
      },
      {
        path: '/posts/edit/:id',
        name: 'post-edit',
        component: () => import('../views/post/editView.vue'),
      },
    ]
  },
  //==========================
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('../templates/mwg-cp/aboutUsView.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('../templates/mwg-cp/contactUsView.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('../templates/mwg-cp/PolicyView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../templates/mwg-cp/newsView.vue')
  },
  {
    path: '/news/:permalink',
    name: 'news/detail',
    component: () => import('../templates/mwg-cp/newsDetailView.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../templates/mwg-cp/JoinView.vue')
  },
  {
    path: '/join/:any',
    name: 'join-detail',
    component: () => import('../templates/mwg-cp/JoinDetailView.vue')
  },
  {
    path: '/apply/:any',
    name: 'apply',
    component: () => import('../templates/mwg-cp/ApplyView.vue')
  },
  {
    path: '/brands',
    name: 'brands',
    component: () => import('../templates/mwg-cp/brandsView.vue')
  },
  {
    path: '/login',
    name: 'login-juga',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/LogoutView.vue')
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
