

import { createStore } from 'vuex'

import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import post from './modules/post';
import dashboard from './modules/dashboard';

import contact from './modules/contact';

export default createStore({
  modules: {
    auth,
    post,
    dashboard,
    contact
  },
  plugins: [createPersistedState()]
});
