import axios from 'axios';
import MainApp from '@/router'

const cors = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*' // Could work and fix the previous problem, but not in all APIs
      }
}

const state = {
    list:null
};

const getters = {
    
};

const actions = {

    async getSuperDashboard({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }

        console.log(headers,"TOKEN")

        const response = await axios.get('dashboard-data',{ params: form, headers: headers})
        return response
    }
};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
